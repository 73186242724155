// file generated, to update please run "npm run generate:featureflags"
export type FeatureFlagKey =
  | 'addSpot'
  | 'blog'
  | 'dashboard'
  | 'favorite'
  | 'googleTag'
  | 'localMap'
  | 'login'
  | 'mobileMap'
  | 'placeDetailsSummary'
  | 'poi'
  | 'reviews'
  | 'routePlaceDetails'
  | 'smsAppLink'
  | 'tenstreet'
  | 'tripApi'
  | 'truckRestrictions';

export interface IFeatureFlag {
  addSpot: boolean;
  blog: boolean;
  dashboard: boolean;
  favorite: boolean;
  googleTag: boolean;
  localMap: boolean;
  login: boolean;
  mobileMap: boolean;
  placeDetailsSummary: boolean;
  poi: boolean;
  reviews: boolean;
  routePlaceDetails: boolean;
  smsAppLink: boolean;
  tenstreet: boolean;
  tripApi: boolean;
  truckRestrictions: boolean;
}

export const featureFlag: IFeatureFlag = {
  addSpot: false,
  blog: false,
  dashboard: true,
  favorite: false,
  googleTag: true,
  localMap: true,
  login: true,
  mobileMap: true,
  placeDetailsSummary: true,
  poi: true,
  reviews: true,
  routePlaceDetails: true,
  smsAppLink: true,
  tenstreet: false,
  tripApi: true,
  truckRestrictions: true
};
